.min-table-height {
	min-height: 300px;
}

td, th,
.p-datatable-scrollable td.p-frozen-column {
	background: var(--surface-card);
}

p-table tbody tr:hover td,
p-table tbody tr:hover td.p-frozen-column {
	background: var(--surface-100);
}

table th.nowrap,
table td.nowrap {
	white-space: nowrap;
	width: 1px;
}

tr.active td {
	background: var(--surface-100);
}

.p-datatable-scrollable table {
	border-collapse: separate !important;
	border-spacing: 0 !important;
}

.p-datatable-gridlines .p-datatable-thead > tr > th:not(:last-child),
.p-datatable-gridlines .p-datatable-tbody > tr > td:not(:last-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > td:not(:last-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > th:not(:last-child) {
	border-right-width: 0px !important;
	border-left-width: 1px !important;
}

.p-datatable-gridlines .p-datatable-tbody > tr:not(:first-child) > td,
.p-datatable-gridlines .p-datatable-tfoot > tr > td,
.p-datatable-gridlines .p-datatable-tfoot > tr > th {
	border-top-width: 0px !important;
	border-bottom-width: 1px !important;
}

.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-left,
.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-left,
.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-left {
	border-right-width: 2px !important;
}

.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-left:not(:first-child) {
	/*border-right-width: 2px !important;*/
	border-left-width: 0px !important;
}

.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-right:not(:last-child) {
	border-left-width: 2px !important;
	border-right-width: 0px !important;
}

.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right) {
	border-right-width: 1px !important;
	border-left-width: 1px !important;
}

.ellipsis-cell {
	max-width: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cell-grow {
	width: 100%;
}

