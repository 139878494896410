.relation {
	padding: 0 0.2rem;
	margin-right: 0.2rem;
	display: inline-block;
	border-radius: 3px;
}

.relation.relation-magasin {
	background: var(--relation-magasin);
	color: var(--relation-magasin-text);
}

.relation.relation-groupe-utilisateur {
	background: var(--relation-groupe-utilisateur);
	color: var(--relation-groupe-utilisateur-text);
}

.relation.relation-utilisateur {
	background: var(--relation-utilisateur);
	color: var(--relation-utilisateur-text);
}
