.p-button {
  min-width: 0;
  width: auto;
}

.p-button.p-button-xs {
	font-size: 0.5rem;
	padding: 0.5rem 0.25rem;
}

.p-button.p-button-xs .p-button-icon {
	font-size: 0.875rem;
}

.p-button {
	padding: 0.75rem 1rem;
}

.p-button.p-button-icon-only {
	// width: 2.5rem;
	padding: 0.75rem;
}

.p-button.p-button-icon-only.p-button-sm{
	width: 2rem;
	padding: 0.5rem;
}

.p-button.p-button-icon-only.p-button-rounded.p-button-sm {
	width: 2rem;
	height: 2rem;
}

.p-button.p-button-icon-only.p-button-xs {
	height: 1.5rem;
	width: 1.5rem;
	padding: 0;
}

.p-selectbutton .p-button {
	width: auto;
}

.p-knob svg {
	max-width: 100%;
	max-height: 100%;
}

@each $key, $breakpoint in $breakpoints {
	@media screen and (max-width: $breakpoint) {
		button.p-button.label-#{$key}-none,
		p-button.label-#{$key}-none {
			.p-button-label {
				display: none !important;
			}
			.p-button-icon {
				margin: 0;
			}
		}
	}
}

@keyframes button-blink {
	0% {
		background-color: transparent;
		color: var(--primary-color);
		border-color: transparent;
	}
	100% {
		color: var(--primary-color-text);
		background: var(--primary-color);
		border: 1px solid transparent;
	}
}

.button-blink {
	animation-name: button-blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.button-strike::before {
	content: '';
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	right: 0.5rem;
	bottom: 0.5rem;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%"><line x1="0" y1="0" x2="100%" y2="100%" style="stroke:rgb(255,61,50);stroke-width:2" stroke-dasharray="0" /></svg>');
}

.p-button.large-icon .p-button-icon.pi {
	font-size: 1rem;
}

.p-editor-container .p-editor-toolbar.ql-snow button {
	color: var(--quill-button);
}

.ql-snow .ql-picker.ql-size {
	width: auto;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
	position: relative;
	margin: 0;
	right: 0;
	top: 2px;
	float: right;
}


.p-button-text-color {
	color: var(--text-color) !important;
}
