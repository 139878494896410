
$separator: "\\:" !default;
$gutter: .5rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;
$spacer:1rem !default;

$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1480px !default;
$xxxl: 1600px !default;

$breakpoints: (
	'sm': $sm,
	'md': $md,
	'lg': $lg,
	'xl': $xl,
	'xxl': $xxl,
	'xxxl': $xxxl
);

@import 'primeflex/src/_mixins';
@import 'primeflex/src/_grid';
@import 'primeflex/src/_colors';
@import 'primeflex/src/_formlayout';
@import 'primeflex/src/_display';
@import 'primeflex/src/_typography';
@import 'primeflex/src/_flexbox';
@import 'primeflex/src/_spacing';
@import 'primeflex/src/_elevation';
@import 'primeflex/src/_border';
@import 'primeflex/src/_borderradius';
@import 'primeflex/src/_size';
@import 'primeflex/src/_position';
@import 'primeflex/src/_overflow';
@import 'primeflex/src/_zindex';
@import 'primeflex/src/_image';
@import 'primeflex/src/_userselect';
@import 'primeflex/src/_liststyle';
@import 'primeflex/src/_misc';
@import 'primeflex/src/_transition';
@import 'primeflex/src/_transform';
@import 'primeflex/src/_animation';
@import 'primeflex/src/_utils';
