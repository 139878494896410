@use "sass:map";

html {
	height: 100%;
	font-size: 14px;
}

@mixin absolute-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

body {
	padding: 0 !important;
	margin: 0 !important;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 1rem;
	background-color: var(--surface-ground);
	color: var(--text-color);
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include absolute-full;
}

.absolute-full {
	@include absolute-full;
}

* {
	min-height: 0;
	min-width: 0;
}

ui-view,
root,
.app-content {
	display: block;
	height: 100%;
	position: relative;
}

.app-content {
	padding: 6rem 1.5rem 1.5rem;
}

@media screen and (max-width: map.get($breakpoints, 'md')) {
	.app-content {
		padding: 5.5rem 0rem 0rem;
	}

}

.app-content ui-view > * {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}

.p-dialog-content > * {
	position: relative;
	display: block;
	height: 100%;
}

/* masquer les spinner sur input number */
input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

a {
	text-decoration: none;
	font-weight: 600;
	color: var(--primary-color)
}

.secondary-color {
	color: var(--secondary-color);
}

.text-error,
.text-danger {
	color: var(--color-error);
}

.align-bottom {
	vertical-align: bottom;
}

.ff-reset {
	font-family: inherit;
}

.p-field-checkbox {
	padding: 0.75rem;

	label {
		margin-left: 0.5rem;
	}
}

.squareit::after {
	content: "";
	display: block;
	padding: 100%;
}

.p-fluid .p-calendar .p-inputtext {
	// min-width: 190px;
}

p-slider.ng-invalid .p-slider-handle {
	background-color: var(--color-error);
}

p-rating.ng-invalid .p-rating-icon {
	color: var(--color-error);
}

p-toast {
	position: relative;
	z-index: 10000;
}

.p-toast-icon-close {
	flex-shrink: 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
	max-height: 300px;
	overflow-y: auto;
	max-width: 250px;
	overflow-x: hidden;
}

.p-toast-message-icon {
	flex-shrink: 0;
}

indicateur-tooltip {
	height: 1rem;
}

.height-6rem {
	height: 6rem;
}

.middle {
	vertical-align: middle;
}

.p-dialog {
	max-width:	100%;
}

.link {
	cursor: pointer;
	color: var(--primary-color);
}

.link:hover {
	text-decoration: underline;
}

.bg-wizville {
	background: var(--wizville) !important;
}

.bg-google {
	background: var(--google) !important;
}

.max-width-lg {
	width: 100%;
	max-width: 400px;
}

.max-width-md {
	width: 100%;
	max-width: 250px;
}

.max-width-sm {
	width: 100%;
	max-width: 150px;
}

.pi {
	font-size: 0.99rem;
}

.p-panel-title {
	order: 0 !important;
}

.p-panel-icons {
	margin-left: auto;
}

p-panel.no-content > div > div > div.p-panel-content {
	padding: 0;
}

.p-toolbar-group-left {
	flex-grow: 1;
}

.p-fileupload .p-fileupload-content {
	padding: 1rem 0;
}

.p-fileupload-file {
	padding: 0.25rem 1rem;
}

.p-fileupload-file:hover {
	background: var(--disabled-bg);
}

.autocomplete-input-icon-left {
	position: relative;
}
.autocomplete-input-icon-left p-autoComplete input {
	padding-left: 2rem;
}

.autocomplete-input-icon-left label {
	position: absolute;
	z-index: 1;
	color: var(--text-color);
	left: 0.5rem;
	top: 0.2rem;
	line-height: 1rem;
}

.center-all {
	display: flex;
	justify-content: center;
	align-items: center;
}


.keyword {
	color: var(--primary-color);
	background: rgba(63, 81, 181, 0.12);
	display: inline-block;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
	margin-right: 0.5rem;
}

.p-card .p-card-content {
	padding: 0;
}

.text-color-inherit {
	color: inherit !important;
}

.tooltip-large {
	max-width: 20rem;
}

.ql-align-center {
	text-align: center;
}

.ql-align-center > * {
	margin: auto;
}

.ql-align-right {
	text-align: right;
}

.ql-align-right > * {
	margin-left: auto;
}


.p-inline-message-icon {
	flex-shrink: 0;
}

.p-inline-message {
	align-items: start !important;
}


.p-toast .p-toast-message .p-toast-message-content {
	padding: 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
	font-size: 1.5rem;
}

.p-toast .p-toast-message .p-toast-icon-close {
	height: 1.5rem;
	width: 1.5rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
	margin: 0.2rem 0 0.5rem 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
	margin: 0;
}


.p-dataview .p-dataview-content {
	padding: 0 !important;
}




p-tag.tag-soft .p-tag {
	background: var(--primary-color-active);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-highlight {
	font-weight: bold;
	color: var(--text-color);
}

.p-dataview .p-dataview-content {
	background: transparent;
}

.word-break-all {
	word-break: break-all;
}

.inline-grid {
	display: inline-grid;
}

.custom-toggleable-panel .p-panel-toggler {
	display: none !important;
}

.custom-toggleable-panel .p-panel-header {
	align-items: start;
}


.search-widget-result {
	max-width: 30rem;
}

.search-widget-footer {
	position: sticky;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	background: var(--surface-0);
}

.p-autocomplete-clear-icon {
	right: 1rem;
	color: var(--primary-color);
}

.p-autocomplete-loader {
	color: var(--maskbg)
}

.search-overlay {
	width: 30rem;
}

.search-overlay .p-overlaypanel-content {
  padding: 1rem 0.5rem 0.5rem;
}

.search-widget-results {
	max-height: 30rem;
	overflow-y: auto;
}

.search-widget-results > .item {
	border-bottom: 1px solid var(--border-color);
	min-height: 4rem;
	padding: 0.5rem 0;
}

.search-widget-results > .item > .liste-media-thumb {
	width: 3rem;
	margin-right: 1rem;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}
