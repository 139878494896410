.p-treeselect-clear-icon {
	position: absolute;
	top: 50%;
	right: 1.75rem;
	margin-top: -.5rem;
	cursor: pointer;
}

.disabled-greyed-out .p-treenode-content:not(.p-treenode-selectable) {
	color: var(--disabled-color);
}

.p-treenode-content {
	white-space: nowrap;
}

.p-treeselect-filter-icon {
	right: 1rem;
}

.p-treenode-content {
	white-space: normal;
}

.p-treenode-children > p-treenode > li {
	// border-left: 1px solid var(--surface-200);
	position: relative;
}

.p-treenode-children > p-treenode > li:before {
  content: "";
  position: absolute;
	top: 0;
	left: 0;
  height: 1.25rem;
  width: 3px;
  border-left: 1px solid var(--surface-300);
  border-bottom: 1px solid var(--surface-300);
}

.p-treenode-children > p-treenode > li.p-treenode-leaf:before {
	width: 1rem;
}

// .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
// 	width: 1.75rem !important;
// }

.p-tree .p-tree-container .p-treenode .p-treenode-content {
	padding: 0 1rem 0 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
	margin: 0;
}
