.p-inputtext {
	padding: 0.75rem;
}

.p-input-icon-right > .p-inputtext {
	padding-right: 1.3rem;
}

.p-input-icon-right > i:last-of-type {
	right: 0.5rem;
}

.p-inputtext:enabled:read-only:hover,
.p-inputtext:enabled:read-only:focus {
	border-color: #ced4da;
	box-shadow: none;
}

.p-inputtext:enabled:read-only,
p-treeselect.readonly .p-treeselect,
p-autocomplete.readonly .p-inputtext
 {
 	border-radius: 0;
	border-top: 1px solid transparent !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
}

input[required],
input[ng-reflect-required="true"],
textarea[ng-reflect-required="true"],
p-dropdown[ng-reflect-required="true"] input.p-dropdown-label {
	&::placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&::-ms-input-placeholder,
	&::-webkit-input-placeholder {
		color: var(--color-error);
	}
}

input:required::placeholder,
textarea:required::placeholder,
p-dropdown[ng-reflect-required="true"] .p-dropdown-label.p-placeholder {
	color: var(--color-error);
	opacity: 0.75;
}
