.p-picklist-list.p-picklist-source, .p-picklist-list.p-picklist-target {
	max-height: 150px;
	overflow-y: auto;
}

.p-picklist .p-picklist-list .p-picklist-item {
	padding: 0.5rem 1rem;
}

.p-picklist .p-picklist-list .p-picklist-item .disabled {
	color: var(--disabled-color);
}
