vignette-selector vignette-display,
section-selector vignette-display,
.p-treeselect-panel vignette-display {
	width: 1rem;
	height: 1rem;
	margin-right: 1rem;
}

section-tree vignette-display {
	flex-shrink: 0;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
}

.vignette-selector-panel img {
	max-width: 3rem;
	max-height: 3rem;
}

vignette-display,
.vignette-display {
	display: inline-block;
	width: 3.5rem;
	height: 3.5rem;
	max-width: 100%;
	max-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

vignette-display img {
	max-width: 100%;
	max-height: 100%;
}

vignette-display.vignette-xs,
.vignette-display.vignette-xs {
	width: 2rem;
	height: 2rem;
}
