@mixin invert-responsive-style-class($prop, $map, $states: false) {
	@each $key, $val in $map {
		.#{$prefix}#{$key} {
			#{$prop}: #{$val} !important;
		}
	}

	@if ($states) {
		@each $key, $val in $map {
			.focus#{$separator} {
				&#{$prefix}#{$key}:focus {
					#{$prop}: #{$val} !important;
				}
			}

			.hover#{$separator} {
				&#{$prefix}#{$key}:hover {
					#{$prop}: #{$val} !important;
				}
			}

			.active#{$separator} {
				&#{$prefix}#{$key}:active {
					#{$prop}: #{$val} !important;
				}
			}
		}
	}


	@each $key, $val in $breakpoints {
		@media screen and (max-width: #{$val}) {
			.under-#{$key + $separator} {
				@each $key, $val in $map {
					&#{$prefix}#{$key} {
						#{$prop}: #{$val} !important;
					}
				}

				@if ($states) {
					@each $key, $val in $map {
						&focus#{$separator} {
							&#{$prefix}#{$key}:focus {
								#{$prop}: #{$val} !important;
							}
						}

						&hover#{$separator} {
							&#{$prefix}#{$key}:hover {
								#{$prop}: #{$val} !important;
							}
						}

						&active#{$separator} {
							&#{$prefix}#{$key}:active {
								#{$prop}: #{$val} !important;
							}
						}
					}
				}
			}
		}
	}
}
