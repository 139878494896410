.p-multiselect {
	max-width: 100%;
	width: 100%;
}

.p-multiselect-clear-icon {
	position: absolute;
	top: 50%;
	right: 1.75rem;
	margin-top: -.5rem;
	cursor: pointer;
}

.p-multiselect-item.p-disabled * {
	pointer-events: auto !important;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: 0.5rem 1rem 0;
	flex-wrap: wrap;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	margin-bottom: 0.5rem;
}
