:root.elton {
--border-radius: 4px;
	--border-color: #e5e5e5;
	--content-padding: 1rem;
	--color-error: #B71C1C;
	--focus-ring: none;
	--font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
	--inline-spacing: 0.5rem;
	--maskbg: rgba(0, 0, 0, 0.32);
	--primary-color-text: #fff;
	--primary-color: #e30073;
	--primary-color-text-hover: #ffffff;
	--primary-color-hover: rgb(227, 0, 115, 0.92);
	--primary-color-focus: rgb(182, 0, 92, 0.76);
	--primary-color-active: rgb(159, 0, 81, 0.68);

	--secondary-color-text: #ffffff;
	--secondary-color: #0096df;
	--secondary-color-text-hover: #ffffff;
	--secondary-color-hover: rgb(0, 135, 201, 0.92);
	--secondary-color-focus: rgb(0, 120, 178, 0.76);
	--secondary-color-active: rgb(0, 105, 156, 0.68);

	--placeholder: rgba(0, 0, 0, 0.5);
	--app-menu-background:  #2d3133; /* #2e3337; #767676 */
	--surface-0: #ffffff;
	--surface-50: #FAFAFA;
	--surface-100: #F5F5F5;
	--surface-200: #EEEEEE;
	--surface-300: #E0E0E0;
	--surface-400: #BDBDBD;
	--surface-500: #9E9E9E;
	--surface-600: #757575;
	--surface-700: #616161;
	--surface-800: #424242;
	--surface-900: #212121;
	--surface-a: #ffffff;
	--surface-b: #fafafa;
	--surface-border: rgba(0, 0, 0, .12);
	--surface-c: rgba(0, 0, 0, .04);
	--surface-card: #ffffff;
	--surface-d: rgba(0, 0, 0, .12);
	--surface-e: #ffffff;
	--surface-f: #ffffff;
	--surface-ground: #ecf0f5;
	--surface-hover: rgba(0, 0, 0, .04);
	--surface-overlay: #ffffff;
	--surface-section: #ffffff;
	--text-color: rgba(0, 0, 0, 0.87);
	--disabled-bg: rgba(0, 0, 0, 0.12);
	--disabled-color: rgba(0, 0, 0, 0.38);

	--quill-button: var(--surface-700);
	--quill-button-hover: var(--surface-900);

	--relation-magasin: var(--surface-500);
	--relation-magasin-text: var(--text-color);
	--relation-groupe-utilisateur: var(--primary-color);
	--relation-groupe-utilisateur-text: var(--primary-color-text);
	--relation-utilisateur: var(--secondary-color);
	--relation-utilisateur-text: var(--secondary-color-text);
}
// charte elton
// bleu: 0096df
// rose: e30073
// jaune: fbe90d
// vert: a6c30f

:root.elton .logo-enseigne {
	background-image: url('/assets/elton_logo-250.png');
}

:root.elton .logo-enseigne-topbar {
	background-image: url('/assets/elton_logo-250-white.png');
}

:root.elton .google-my-business {
	background-image: url('/assets/google-my-business-text.png');
}
