/* You can add global styles to this file, and also import other style files */

@import 'styles/_vars';
@import 'styles/primeflex';
@import 'styles/theme';
@import 'styles/_mixins';
@import 'styles/_display';
@import 'styles/_size';

@import 'styles/base';
@import 'styles/browse';
@import 'styles/button';
@import 'styles/inputtext';
@import 'styles/grid';
@import 'styles/logo';
@import 'styles/media';
@import 'styles/menu';
@import 'styles/multiselect';
@import 'styles/picklist';
@import 'styles/relation';
@import 'styles/table';
@import 'styles/treeselect';
@import 'styles/vignette';

@import 'styles/aviva';
@import 'styles/elton';
