@mixin fontdef($family, $FontType: "Regular", $weight: 400, $path: "./fonts") {
  font-family: "#{$family}";
  src: url("#{$path}/#{$family}-#{$FontType}.woff2") format("woff2"),
    url("#{$path}/#{$family}-#{$FontType}.woff") format("woff");
  font-weight: #{$weight};
  font-style: normal;
}

@font-face {
  @include fontdef("Roboto", "Regular", 400);
}

@font-face {
  @include fontdef("Roboto", "SemiBold", 500);
}

@font-face {
  @include fontdef("Roboto", "Bold", 700);
}

:root {
  --font-family: "Roboto";
}

$prefix: '';

$top-bar-height: 6rem;
$col-left-width: 28rem;
