@use "sass:map";

browse {


}

browse-advanced .p-dataview-header {
	padding-bottom: 0 !important;
	margin-bottom: 0.5rem;
}

browse-advanced .p-dataview-emptymessage {
	background: var(--surface-card);
}

.browse-section-tree {
	position: fixed;
	width: 100%;
	overflow: auto;
	max-width: $col-left-width;
	max-height: calc(100% - $top-bar-height);
	height: 100%;
	transition: width 400ms, padding 400ms;
}

.browse-display {
	position: relative;
	left: $col-left-width;
	max-width: calc(100% - $col-left-width);
	transition: width 400ms, padding 400ms;
}

.section-view.previous {
	display: none;
}

@media screen and (max-width: map.get($breakpoints, 'md')) {
	browse > .browse-container {
		margin: 0;
	}
	.state-browse .browse-section-tree {
		// max-width: calc(100% - 2rem);
		max-width: calc(100%);
		padding: 0;
	}

	.state-browse .browse-display {
		display: none;
	}

	.state-section .browse-section-tree {
		// width: 0;
		// padding-left: 0;
		// padding-right: 0;
		// overflow: hidden;
		display: none;
	}
	.state-section .browse-display {
		max-width: 100%;
		left: 0;
		padding: 0;
	}

	.section-view.previous {
		display: inline;
	}

}



.ressource-brouillon {
	color: var(--disabled-color) !important;
}

// .ressource-archive {
// 	color: var(--disabled-color) !important;
// }

.ressource-entry {
	// background: var(--surface-500);
	// color: var(--primary-color-text);
	color: var(--text-color);
	transition: background 300ms, color 300ms;
}

.ressource-name {
	margin: 0.5rem 0;
}

.ressource-entry:hover {
	background: var(--primary-color-active);
	// color: var(--primary-color-text);
}
