.logo,
.logo-enseigne {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

auth .logo-enseigne {
	height: 100px;
	width: 250px;
	margin: 0 50px;
}

.service-unavailable .logo-enseigne {
	height: 100px;
	width: 250px;
	margin: 0 50px;
}

