media-display {
	max-width: 100%;
	max-height: 100%;
	// display: block;
	display: flex;
	align-items: center;
}

media-display img {
	max-width: 100%;
	max-height: 100%;
	// min-width: 128px;
}

.liste-media-thumb {
	width: 4rem;
	max-width: 4rem;
}

.media-shortcode-form .liste-media-thumb {
	width: 2rem;
	max-width: 2rem;
	padding: 0;
}

.liste-media-thumb-fake {
	width: 5rem;
	// background: var(--surface-300);
}

ressource-renderer media-display {
	display: inline-block;
}

p-galleriacontent {
	width: 100%;
	height: 100%;
}

.p-galleria-fullscreen {
	height: 100%;
	display: flex;
}

.p-galleria-fullscreen .p-galleria-content {
	height: 100%;
	width: 100%;
}

.p-galleria-item-wrapper {
	height: 100%;
}

p-galleriaitem {
	flex-grow: 1;
}

p-galleriathumbnails {
	flex-shrink: 0;
}

.p-galleria-thumbnail-item media-display img {
  max-height: 100px;
}

.p-galleria-close {
	z-index: 10000;
}

.p-fileupload-thumb {
	width: 50px;
	text-align: center;
	margin-right: 1rem;
	position: relative;
}

.p-fileupload-thumb img {
	max-width: 100%;
}

.media-item:hover {
	background: var(--surface-200);
}

medias-display .media-item-grid {
	// width: 20rem;
}

medias-display .p-dataview .p-dataview-content {
	padding: 0 0.5rem 0 0 !important;
}

medias-display .p-dataview.p-dataview-grid > .p-dataview-content > .p-grid > div {
	border: none;
}

medias-display .p-dataview.p-dataview-grid > .p-dataview-content > .p-grid {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	margin-top: -0.5rem;
}

medias-display .p-dataview.p-dataview-grid > .p-dataview-content > .p-grid > .col,
medias-display .p-dataview.p-dataview-grid > .p-dataview-content > .p-grid > [class*="col-"] {
	padding: 0.25rem 0.5rem;
}

media-display.media-video {
	aspect-ratio: 16/9;
	position: relative;
}

media-display.media-video > div,
media-display video,
media-display iframe {
	width: 100%;
	height: 100%;
}

.shortcode-panel .p-overlaypanel-close {
	display: none;
}


.pdf-viewer {
	width: 100%;
	height: calc(100vh - 15rem)
}
